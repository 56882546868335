




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { EventName } from '@/models'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import type { Route } from 'vue-router'

@Component({
	components: {
		BotaoDeDrawer,
		IconesDaToolbarPadroes,
	},
})
export default class AppToolbarComTitulo extends Vue  {
	@Prop({ type: String, default: '' }) titulo!: string
	botoesCustomizados: BotaoCustomizado[] = []

	created() {
		PluginModule.on('adicionar-botao-customizado', this.adicionarBotaoCustomizado)
	}

	executarEventoDoBotaoCustomizado(botao: BotaoCustomizado) {
		PluginModule.emit(
			botao.nomeDoEvento as EventName,
			{ token: UserLoginStore.token },
		)
	}

	adicionarBotaoCustomizado(botao: BotaoCustomizado) {
		const indice = this.botoesCustomizados.findIndex(
			botaoCustomizado => botaoCustomizado.id === botao.id,
		)
		indice === -1
			? this.botoesCustomizados.push(botao)
			: this.botoesCustomizados.splice(indice, 1, botao)
	}

	@Watch('$route', { immediate: true, deep: true })
	async onChangeRoute(route: Route) {
		PluginModule.emit('alternar-rota', {
			name: route.name,
		})
		this.botoesCustomizados = this.botoesCustomizados.filter(
			botao => !botao.rotaParaExibicao || botao.rotaParaExibicao === route.name,
		)
	}
}

type BotaoCustomizado = {
	id: string
	texto: string
	nomeDoEvento: string
	icone?: string
	rotaParaExibicao?: string
}

